/*
* @Author: 曹俊杰
* @Date: 2024-10-10 22:44:09
 * @Last Modified by: 曹俊杰
 * @Last Modified time: 2024-10-11 11:31:45
*/课程详情

<template>
    <div class="detail">
        <div class="detail__title">{{ activeData.title }}</div>
        <div class="detail__box">
            <div class="detail__content">
                <video width="833px" height="468px" controls :poster="`${$oss}${activeData.cover}`" :src="`${$oss}${activeData.url}`"></video>
            </div>
            <div class="detail__menu">
                <div class="detail__menu__title">课程：{{ detail.name }}</div>
                <div class="detail__menu__top">
                    <div class="item" :class="{ active: menu.active == 'menu' }" @click="menuClick('menu')">
                        <i class="el-icon-ali-qianshuchuzheng"></i>
                        <span> 目录（{{ detail.videos.length }}）</span>
                    </div>
                    <div class="item" :class="{ active: menu.active == 'info' }" @click="menuClick('info')">
                        <i class="el-icon-ali-qianshuchuzheng"></i>
                        <span>课程简介</span>
                    </div>
                </div>
                <div class="detail__menu__list" v-show="menu.active == 'menu'">
                    <div class="detail__menu__list__item" v-for="item in detail.videos" :key="item.videoId"
                        :class="{ active: item.videoId == menu.videoId }" @click="menu.videoId = item.videoId">
                        <span>{{ item.title }}</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M3.5 0.2C3.5 0.0895429 3.58954 0 3.7 0H5.8C5.91046 0 6 0.089543 6 0.2V9.8C6 9.91046 5.91046 10 5.8 10H3.7C3.58954 10 3.5 9.91046 3.5 9.8V0.2ZM0 3.2C0 3.08954 0.089543 3 0.2 3H2.3C2.41046 3 2.5 3.08954 2.5 3.2V9.8C2.5 9.91046 2.41046 10 2.3 10H0.2C0.0895432 10 0 9.91046 0 9.8V3.2ZM7.2 5C7.08954 5 7 5.08954 7 5.2V9.8C7 9.91046 7.08954 10 7.2 10H9.3C9.41046 10 9.5 9.91046 9.5 9.8V5.2C9.5 5.08954 9.41046 5 9.3 5H7.2Z"
                                fill="#0261FF" />
                        </svg>
                    </div>
                </div>
                <div class="detail__menu__info" v-show="menu.active == 'info'">
                    {{ detail.summary }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            menu: {
                active: 'menu',
                videoId: null
            },
            detail: {
                bought: false,
                courseId: null,
                cover: "",
                createdAt: null,
                name: "",
                price: null,
                rank: null,
                summary: "",
                videoCount: null,
                videos: [],
                viewCount: null
            }
        }
    },
    computed: {
        activeData() {
            const { videos } = this.detail;
            if (videos.length) {
                return videos.find(res => res.videoId == this.menu.videoId)
            } else {
                return {
                    author: null,
                    cover: "",
                    createdAt: null,
                    summary: null,
                    title: "",
                    updatedAt: null,
                    url: "",
                    videoId: null,
                    viewCount: null
                }
            }
        }
    },
    methods: {
        menuClick(res) {
            this.menu.active = res
        },
        async getCourseDetail() {
            const { params } = this.$route
            const { status, data } = await this.$api.getCourseDetail(params)
            if (status == 200) {
                this.detail = data;
                if (data.videos.length) {
                    this.menu.videoId = data.videos[0].videoId
                } else {
                    this.$message({
                        type: "error",
                        message: '当前课程没有视频内容，请联系管理员'
                    })
                }
            }
        }
    },
    watch: {
        "$route.params": {
            handler() {
                this.$nextTick(() => {
                    this.getCourseDetail()
                })
            },
            immediate: true
        }
    }

}
</script>

<style lang="scss" scoped>
.detail {
    height: 543px;

    &__title {
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 75px;
    }

    &__box {
        display: flex;
        justify-content: space-between;
    }

    &__content {
        width: 832px;
        height: 468px;
        background: #000;
    }

    &__menu {
        width: 426px;
        height: 468px;
        background: #fff;

        &__title {
            font-family: "Source Han Sans CN";
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 60px;
            padding: 0 20px;
        }

        &__top {
            display: flex;
            background: #FBFCFD;

            .item {
                width: 50%;
                font-family: Source Han Sans CN;
                font-size: 15px;
                font-weight: 400;
                line-height: 44px;
                padding-left: 20px;
                color: #999;
                cursor: pointer;

                i {
                    margin-right: 6px;
                }
            }

            .active {
                color: #262626;
            }
        }

        &__list {
            margin-top: 10px;
            height: calc(8 * 40px);
            padding: 0 10px;
            overflow-y: auto;

            &__item {
                height: 40px;
                padding: 0 10px;
                line-height: 40px;
                font-family: "Source Han Sans CN";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: space-between;

                svg {
                    display: none;
                }
            }

            .active {
                border-radius: 4px;
                background: #F3F6F8;
                color: #0261FF;

                svg {
                    display: block;
                }
            }
        }

        &__info {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            padding: 20px 20px;
        }
    }
}
</style>